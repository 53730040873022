/* ******************************
 * METHOD > getCardRedeemableDays
 ***************************** */

export function getCardRedeemableDays(publishedDate, allowedDays = 90) {
	let timeUsed = (Date.parse(new Date()) - Date.parse(new Date(publishedDate))) / 1000
	// 24h * 60m * 60s == 86400 perday
	const daysUsed = Math.floor(timeUsed / 86400)
	const daysLeft = allowedDays - daysUsed
	if (daysLeft <= 0) {
		return 0
	} else {
		return daysLeft
	}
}
