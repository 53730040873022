import React from 'react'
import { Button, Icon } from '@chakra-ui/react'
import keys from '../NavBar/keys'
import { ExitToAppRounded } from '../Icon'

const ExitButton = ({ onClick, iconDirection, text, ...rest }) => {
	return (
		<Button variant="control" onClick={onClick} {...rest}>
			<ExitToAppRounded transform={iconDirection == 'left' ? 'rotate(180deg)' : 'none'} />
			{text || 'Exit'}
		</Button>
	)
}

ExitButton.defaultProps = {
	iconDirection: 'left',
}

export default ExitButton
