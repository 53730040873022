import * as Yup from 'yup'

export default Yup.object().shape({
	email: Yup.string()
		.email('Please provide a valid email address')
		.matches(
			// eslint-disable-next-line no-useless-escape
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			'This is not a correct email format.'
		)
		.required()
		.trim('Please remove all spaces.'),
	emailConfirmation: Yup.string()
		.email('')
		.oneOf([Yup.ref('email'), null], 'Emails must match.'),
})
