import React, { memo } from 'react'
import { Box, Flex, Grid, Image } from '@chakra-ui/react'
import useWindowSize from '../../../../hooks/useWindowSize'
import useImageGenerator from '../../../../hooks/useImageGenerator'

const HeroImage = memo((props) => {
	const { width } = useWindowSize()

	let path = null
	if (width <= 428) {
		path = `/assets/images/gift-card/gift-card-banner-mobile`
	} else {
		path = `/assets/images/gift-card/gift-card-banner-desktop`
	}

	const img = useImageGenerator({
		path: path,
		ext: 'png',
	})

	if (img) {
		return (
			<Box d={['flex', 'grid']} w="full" justifyContent={'center'} m="0">
				<Image w="full" h="fit-content" src={img.src} srcSet={img.srcset} />
			</Box>
		)
	}
	return <></>
})

export default HeroImage
