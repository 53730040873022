import React from 'react'
import { useDispatch } from 'react-redux'
import useFormState from '../../../../../../hooks/useFormState'
import { chakra, LightMode, Button, Text, Link, VStack } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { verifySingleEmail } from '../../../../../../../api/actions'
import PropTypes from 'prop-types'
import SimpleEmail from '../../../../../molecules/controls/InputEmail/SimpleEmail'

const RedeemForm = ({ onSubmit, isSubmitting }) => {
	/**
	 * We need to prevent double submit,
	 * so let's check if we're submitting the form
	 * and pass in email verification
	 * */
	const formSubState = useFormState('RedeemCash')
	const { status, verification } = formSubState
	const dispatch = useDispatch()

	const { handleSubmit, errors, setValue, watch, control } = useFormContext()

	/** Watch current form values */
	const values = watch()

	const onApplySuggestion = (e) => {
		e.preventDefault()
		let delayVerifyTimer
		if (verification?.result?.suggestion) {
			const prefix = values.email.split('@')[0]
			const suggested = prefix + '@' + verification?.result?.suggestion

			setValue('email', suggested)
			delayVerifyTimer = setTimeout(() => {
				dispatch(
					verifySingleEmail({
						email: suggested.toLowerCase(),
						provider: 'sendgrid',
						source: 'gc redemption email',
					})
				)
			}, 1000)

			return () => {
				clearTimeout(delayVerifyTimer)
			}
		}
	}

	return (
		<chakra.form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<VStack maxW="460px" mx="auto" mb="1rem" spacing="1rem">
				<Text color="dark">To which email should we send your gift card?</Text>

				<SimpleEmail control={control} errors={errors} name="email" type="email" placeholder="Your Email" />

				{values.email && (
					<SimpleEmail
						control={control}
						errors={errors}
						name="emailConfirmation"
						type="email"
						placeholder="Email confirmation"
					/>
				)}

				{verification?.result?.suggestion && (
					<Text color="dark">
						Did you mean?&nbsp;
						<Link variant="link" onClick={onApplySuggestion}>
							{verification?.result?.suggestion}
						</Link>
					</Text>
				)}

				<LightMode>
					<Button type="submit" variant="solid" size="lg" isLoading={isSubmitting}>
						<span>Claim Your Gift Card</span>
					</Button>
				</LightMode>
			</VStack>
		</chakra.form>
	)
}

RedeemForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
}

export default RedeemForm
