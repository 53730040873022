import { useSelector } from 'react-redux'

const useFormState = (name = undefined) => {
	if (!name) throw new Error('No name has been provided to return the proper form state.')

	const state = useSelector((state) => state.forms[name])

	return state
}

export default useFormState
