import { Image, VStack, Text, Box, Heading } from '@chakra-ui/react'
import React from 'react'
import useImageGenerator from '../../../../../../hooks/useImageGenerator'
import Card from '../../../../../atoms/Card'

const Redeemed = () => {
	const icon = useImageGenerator({
		path: '/assets/images/gift-card/gift-card-success',
		ext: 'png',
	})

	return (
		<>
			<Box my="2rem" textAlign="center" mx={['1rem', 0]}>
				<Heading as="h2" size="xl" variant="hero">
					Gift Card Claimed
				</Heading>
				{/* <Text>This gift card has been redeemed.</Text> */}
			</Box>

			<Card mb="1rem" px={['1rem', '2rem']} py="2rem">
				<VStack spacing="0.5rem" w="full">
					<Image w="86px" h="60px" src={icon.src} srcSet={icon.srcset} />

					<Text color="dark">You claimed your gift — check your email to access your eGift Card.</Text>
				</VStack>
			</Card>
		</>
	)
}

export default Redeemed
