import React from 'react'
import { Controller } from 'react-hook-form'
import {
	FormLabel,
	InputGroup,
	FormHelperText,
	FormControl,
	Input,
	InputRightElement,
	FormErrorMessage,
} from '@chakra-ui/react'
import { EmailRounded } from '../../../atoms/Icon'

const ControlledInputEmail = (props) => {
	const {
		// react-hook-form attributes
		control,
		errors,
		defaultValue,
		// setError,
		// Base input attributes
		name,
		label,
		placeholder,
		helperText,
		// Additional styling props
		readOnly,
		// rightElement,
		textAlign,
		// For verified email, pass in following props
		formState,
		provider,
		primaryEmail,
		source,
		verify,
		// Verify this..... maybe passed through control?
		// clearErrors,
		index,
		onBlur,
		// Rest of props, whatever this might be...
		...rest
	} = props

	/**
	 * Error Management
	 */
	let isError = false
	let errorMessage = ''

	if (index !== undefined) {
		/** Obtain field name and key name, wrapping the index */
		let chain = name.split(`[${index}].`)
		// The result array should have 2 segments
		if (chain.length == 2 && chain[0] !== undefined && chain[1] !== undefined) {
			let arrayFieldKey = chain[0],
				fieldKey = chain[1]
			if (
				errors.hasOwnProperty(arrayFieldKey) &&
				errors[arrayFieldKey][index] &&
				errors[arrayFieldKey][index].hasOwnProperty(fieldKey)
			) {
				isError = true
				errorMessage = errors[arrayFieldKey][index][fieldKey].message
			}
		}
	} else if (errors && errors.hasOwnProperty(name) === true) {
		isError = true
		errorMessage = errors[name].message
	}

	let rightElement = <EmailRounded color="gray.300" />

	return (
		<Controller
			id={name}
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={(props, { invalid, isTouched, isDirty }) => {
				// const _onBlur = (e) => {
				// 	onBlur(props.value)
				// }
				return (
					<FormControl isInvalid={isError} {...rest}>
						{label && (
							<FormLabel htmlFor={name} textAlign={textAlign ? textAlign : 'left'}>
								{label}
							</FormLabel>
						)}

						<InputGroup>
							<Input
								disabled={readOnly}
								id={name}
								placeholder={placeholder}
								onKeyUp={(event) =>
									control.setValue(name, event.target.value.toLowerCase().replace(' ', ''))
								}
								{...props}
							/>
							<InputRightElement aria-label="Email Status" children={rightElement} />
						</InputGroup>

						{isError ? (
							<FormErrorMessage display="block" textAlign={textAlign ? textAlign : 'left'}>
								{errorMessage}
							</FormErrorMessage>
						) : (
							helperText && <FormHelperText>{helperText}</FormHelperText>
						)}
					</FormControl>
				)
			}}
		/>
	)
}

export default ControlledInputEmail
