import React, { useEffect, useState } from 'react'
import { Flex, Alert, AlertIcon, Box, Text, VStack, Heading, Link, Divider, LightMode, Spinner } from '@chakra-ui/react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import Card from '../../../../../atoms/Card'
import { formatToDecimalAmount } from '../../../../../../../api/checkout/utils'
import { useEventContext } from '../../../../../../contexts/EventProvider'
import { useEventCashCollection } from '../../../../Event'
import RedeemForm from '../RedeemForm'
import axios from 'axios'
import config from '../../../../../../../../client/config.client'
import HeroImage from '../../../../GiftCollection/HeroImage'
import { getCardRedeemableDays } from './utils'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { loadEvent } from '../../../../../../../api/app/events'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schemaEmail from '../RedeemForm/schema'
import { getUserLocation, verifyEmail } from '../../../../../molecules/controls/InputEmail/utils'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: config.api.baseUrl,
})

// extends dayjs with utc and timezone plugins.
dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Sub-component that handles the Redeem form
 * and redeemable state
 */
const Redeemable = () => {
	const { event } = useEventContext()
	const { amount } = useEventCashCollection(event)
	// const userLocation = useUserLocation()
	const dispatch = useDispatch()

	// get user timezone or guess timzone if null
	let eventTimezone = event?.timezone ? event?.timezone : dayjs.tz.guess()

	/**
	 * Gift Card State
	 */

	const [isSubmitting, setIsSubmitting] = useState(false)

	const methods = useForm({
		defaultValues: { email: null, emailConfirmation: null },
		mode: 'onSubmit',
		resolver: yupResolver(schemaEmail),
		shouldFocusError: true,
	})

	const [error, setError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const location = useLocation()
	const navigate = useNavigate()
	/**
	 * Total Amount $
	 * */
	const [total, setTotal] = useState(formatToDecimalAmount(amount))
	const [searchParams] = useSearchParams()
	// card redemption remaining days
	const [redeemableDays, setRedeemableDays] = useState()
	// Save state when we have a successful response from Tremendous
	// const [submittedAndProcessing, setSubmittedAndProcessing] = useState(false)

	useEffect(() => {
		setTotal(formatToDecimalAmount(amount))
	}, [amount])

	useEffect(() => {
		// get gift card redemption remaining allowed days
		const date = dayjs(event.publishedDate).tz(eventTimezone).toString()
		setRedeemableDays(getCardRedeemableDays(date))
	}, [])

	const onSubmit = async (data) => {
		setIsSubmitting(true)

		/** Format email to be fullproof */
		data.email = String(data.email).trim().toLowerCase()
		/** Delete confirmation field */
		delete data.emailConfirmation

		/** First, let's make sure the email is valid. */
		const verify = await verifyEmail(data.email, { provider: 'sendgrid' })

		/** If the email is not valid, let the user know */
		if (!verify.isValid) {
			setError('email', {
				type: 'error',
				message: 'Invalid email, please check your entry.',
			})
			setIsSubmitting(false)
		} else {
			let userLocation = null
			// Retrieve user location
			const locationResponse = await getUserLocation()

			// Attach user location from state
			if (locationResponse.success) {
				userLocation = locationResponse.data
			}

			/** POST Contribution and retrieve Stripe Checkout session ID */
			instance
				.post(`/v2/tremendous/orders`, {
					eventId: event.id,
					privateKey: event.privateKey,
					email: data.email,
					location: userLocation,
				})
				.then(function (response) {
					console.log(response, 'response')
					// setSubmitting(false)

					// LINK delivery : will include a reward link in response
					// const rewardLink = response.data.data.rewards[0]?.delivery?.link
					// const redirect = `${window.location.origin}/api/v2/tremendous/redirect?url=${rewardLink}`

					// if (rewardLink !== undefined) {
					// 	window.location.replace(redirect)
					// }

					// We are processing through tremendous, let user know it should arrive by mail.
					// setSubmittedAndProcessing(true)
					// Let's add a search param to the page to ensure the status will be persistent upon reload.
					navigate(
						`${location.pathname}${
							location.search ? location.search + '&tremendous_success=true' : '?tremendous_success=true'
						}`
					)

					setTimeout(() => {
						setIsSubmitting(false)
					}, 7000)

					setTimeout(() => {
						// window.location.reload()
						dispatch(loadEvent(event.uuid, { privateKey: event.privateKey }))
					}, 10000)
				})
				.catch(function (error) {
					setIsSubmitting(false)
					if (error.response) {
						setError(true)
						setErrorMessage(error.response.data.message)
					}
				})
		}
	}

	const tremendousSuccess = searchParams.has('tremendous_success')

	return (
		<>
			<Box my="2rem" textAlign="center" mx={['1rem', 0]}>
				<Heading as="h2" size="lg" variant="hero">
					Claim Gift Card
				</Heading>
			</Box>

			<Card mb="1rem">
				<HeroImage maxH="180px" />
				{/* <Image src={cover.src} srcSet={cover.srcset} alt="Gift Card Options" /> */}
				<Box w="full" pb="2rem" pt="0.5rem" px={['1rem', '2rem']}>
					<VStack spacing="0.5rem" w="full" mb="1rem">
						<Text color="dark">Amount collected:</Text>
						<Heading size="2xl" tag="h1" variant="hero" color="dark">
							{total}
						</Heading>
						<Text color="dark">USD 🇺🇸</Text>
						<Text color="dark">Redeem collected funds then choose a gift card of your choice — enjoy!</Text>
					</VStack>

					{error && (
						<Box maxW="460px" mx="auto">
							<LightMode>
								<Alert status="error" borderRadius="base" mb="1rem">
									<AlertIcon />
									<Text color="dark">{errorMessage}</Text>
								</Alert>
							</LightMode>
						</Box>
					)}

					{!isSubmitting && !tremendousSuccess && (
						<FormProvider {...methods}>
							<RedeemForm onSubmit={onSubmit} isSubmitting={isSubmitting} />
						</FormProvider>
					)}

					{isSubmitting && (
						<Flex w="full" justifyContent="center" mb="1rem">
							<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="blue.500" size="md" />
						</Flex>
					)}

					{tremendousSuccess && (
						<Box maxW="460px" mx="auto">
							<LightMode>
								<Alert status="success" borderRadius="base" mb="1rem">
									<AlertIcon />
									<Text color="dark">
										<strong>Success!</strong> Please check your email for a confirmation from
										Tremendous.
									</Text>
								</Alert>
							</LightMode>
						</Box>
					)}

					{!tremendousSuccess && (
						<VStack spacing="0">
							<Text color="#4A555F">
								You have{' '}
								<Text as="strong" color="gray.900">
									{redeemableDays} days
								</Text>{' '}
								left
							</Text>
							<Text color="#4A555F">to claim</Text>
						</VStack>
					)}

					<VStack spacing="0.5rem" maxW="400px" mx="auto" textAlign="center">
						<Divider borderStyle="dashed" borderColor="#D0D6DD" my="1rem" />
						<Text color="dark" fontSize="0.75rem">
							By redeeming your gift amount, you agree with the recipient policy in the{' '}
							<Link
								variant="link"
								target="_blank"
								href="https://www.vidday.com/terms-of-use#section-11"
								rel="noopener noreferrer">
								VidDay Terms of Use
							</Link>
							.
						</Text>

						<Text color="dark" fontSize="0.75rem">
							Gift cards are issued by our third-party supplier, Tremendous —{' '}
							<Link
								variant="link"
								target="_blank"
								href="https://www.tremendous.com/terms"
								rel="noopener noreferrer">
								their terms of service
							</Link>{' '}
							will apply to your gift card.
						</Text>
						<Link
							fontSize="0.75rem"
							variant="link"
							target="_blank"
							href="https://help.vidday.com/ive-received-a-gift-card-with-my-video-what-is-it-and-how-do-i-claim-it/"
							rel="noopener noreferrer">
							Trouble Redeeming?
						</Link>
					</VStack>
				</Box>
			</Card>
		</>
	)
}

export default Redeemable
