import React, { useEffect, useState } from 'react'
import FooterBase from '../../../molecules/FooterBase'
import { Container, Heading, HStack } from '@chakra-ui/react'
import NavBar from '../../../atoms/NavBar'
import BackButton from '../../../atoms/nav-controls/BackButton'
import ExitButton from '../../../atoms/nav-controls/ExitButton'
import Card from '../../../atoms/Card'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PublicHeader from '../atoms/PublicHeader'
import { useEventContext } from '../../../../contexts/EventProvider'
import FundersList from '../../GiftCollection/FundersList'
import { useEventCashCollection } from '../../Event'
import Redeemable from './atoms/Redeemable'
import Redeemed from './atoms/Redeemed'
import { useEventHandlersV2 } from '../../../../hooks/useEventHandlers'
import { useRoleContext } from '../../../../contexts/RoleProvider'

const Redeem = () => {
	const { event, isOwner } = useEventContext()
	const { role } = useRoleContext()
	const { uuid } = useParams()
	const navigate = useNavigate()
	const location = useLocation()
	const handlers = useEventHandlersV2(uuid)
	const { funders, amount } = useEventCashCollection(event)

	const [isRedeemable, setIsRedeemable] = useState(funders.filter((f) => f.paid).filter((f) => !f.claimed))
	useEffect(() => {
		const claimable = funders.filter((f) => f.paid).filter((f) => !f.claimed)
		setIsRedeemable(claimable.length > 0)
	}, [event])

	const handleBack = () => navigate(`/e/${uuid}${location.search}`)
	const handleExit = () => {
		navigate(handlers.root)
	}
	const handleSend = () => {
		navigate(handlers.share_recipient)
	}

	const funderListRole = 'creator' // Lint

	//Return view to theatre page
	useEffect(() => {
		if (role != 'recipient' && role != 'creator') {
			handleBack()
		}
	}, [role, event])

	return (
		<>
			{/* <PublicHeader /> */}
			{!isOwner && role == 'recipient' && (
				<NavBar pos="relative">
					<HStack spacing={['4%', '1rem']}>
						<BackButton left onClick={handleBack} />
					</HStack>
				</NavBar>
			)}

			{isOwner && (
				<NavBar>
					<HStack spacing={['4%', '1rem']}>
						<BackButton left onClick={handleBack} />
						<ExitButton left text="leave" onClick={handleExit} />
					</HStack>
				</NavBar>
			)}

			<PublicHeader hideTitle />

			<Container maxW="956px" pb="1rem" variant="main" px="1rem">
				{isRedeemable && <Redeemable />}
				{!isRedeemable && <Redeemed />}

				{amount > 0 && (
					<Card py="2rem" px={['1rem', '2rem']}>
						<Heading as="h3" size="md" color="dark" mb="1rem">
							{funders.length} Contribution{funders.length > 1 ? 's' : ''}
						</Heading>
						<FundersList collection={funders} role={funderListRole} />
					</Card>
				)}
			</Container>
			<FooterBase />
		</>
	)
}

export default Redeem
